import React from "react"

// import feature from "../images/feature.png"
import feature from "../images/Rachaelphoto.jpg"
import SectionHeader from "./section-header"
import { COLORS } from "../styles/constants"

const Content = () => (
  <div style={{ padding: "1rem 1rem", textAlign: "center" }}>
    <SectionHeader
      title="About the Author"
      // description="Don't spend time ripping out unneeded plugins and bloat."
    />
    <content
      style={{
        display: "grid",
        alignItems: "center",
        justifyContent: "center",
        gridTemplateColumns: "repeat(auto-fit, minmax(240px, 340px))",
      }}
    >
      <div>
        <h3>Rachael Jones</h3>
        <p style={{ color: COLORS.gray }}>
          Rachael and both her children have XLH (X-linked Hypophosphatemia). 
          Rachael personally understands the fear and uncertainty that come with a rare disease diagnosis.
          She wrote this book, in collaboration with her children, from a desire for other children and
          families to feel encouraged as well. Most importantly, she desires
          that children and families receive a message of hope, leading to an understanding that someone
          living with XLH can still live a full and meaningful life.
        </p>
      </div>
      <div>
        <img src={feature} alt="a blank card floating over colorful graphics" />
      </div>
    </content>
  </div>
)

export default Content
