import React from "react"
import PropTypes from "prop-types"

// import headerImage from "../images/header.png"
import mainImage from "../images/MaxandMilofrontcover.jpg"
// import mainImage from "../images/main.webp"
import Paper from "../components/paper"

const Header = ({ siteTitle }) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      padding: "1rem 1rem",
      maxWidth:"1440px",
      margin:"auto"
    }}
  >
    {/* <div
      style={{
        backgroundImage: `url(${headerImage})`,
        position: "absolute",
        top: 0,
        zIndex: -5,
        height: "100vh",
        width: "100vw",
        opacity: 0.5,
      }}
    /> */}
    <Paper>
      <h1 style={{ textAlign: "center" }}>Max and Milo</h1>
      <h2 style={{ textAlign: "center" }}>Life with XLH</h2>
      <div style={{ margin: 50, position: "relative" }}>
        <img src={mainImage} alt="Book cover" />
      </div>
      <p style={{ textAlign: "center" }}>
        Max is a boy who enjoys playing with his friends, camping with his family, and going on adventures with his dog, Milo. Max also happens to have a rare disease called XLH. XLH is a rare genetic disorder that affects approximately 1 in 20,000 people and causes a variety of symptoms. Max may have to see many doctors and deal with a variety of symptoms, but he tries to live as normal a life as possible. Along the way, Max learns that he is special and rare, which is a good thing. He also learns that he doesn't have to be embarrassed by his rare condition. Max knows that XLH is something that is just a part of him but is not what defines him.
      </p>
      <div style={{ textAlign: "center" }}>
        <a 
          href="https://www.amazon.com/dp/0578982137"
          target="_blank"
          rel="noreferrer"
          >
            <button>Buy on Amazon</button>
        </a>
      </div>
    </Paper>
  </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
