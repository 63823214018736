import React from "react"

// import feature from "../images/feature.png"
import feature from "../images/maxandmilo1.png"
import SectionHeader from "./section-header"
import { COLORS } from "../styles/constants"

const Resources = () => (
  <div style={{ padding: "1rem 1rem", textAlign: "center" }}>
    <SectionHeader
      title="XLH Resources and Information"
      // description="Don't spend time ripping out unneeded plugins and bloat."
    />
    <content
      style={{
        display: "grid",
        alignItems: "center",
        justifyContent: "center",
        gridTemplateColumns: "repeat(auto-fit, minmax(240px, 340px))",
      }}
    >
      <div>
        <div style={{ color: COLORS.gray }}>
          <h4>XLH Patient Advocacy Organizations</h4>
          <ul>
            <li><a target="_blank" rel="noreferrer" href="https://www.xlhnetwork.org/">XLH Network</a></li>
            <li><a target="_blank" rel="noreferrer" href="https://canadianxlhnetwork.org/">Canadian XLH Network</a></li>
            <li><a target="_blank" rel="noreferrer" href="https://xlhalliance.org//">XLH Alliance</a></li>
            <li><a target="_blank" rel="noreferrer" href="https://www.xlhaustralia.com/">XLH Australia</a></li>
          </ul>
          <h4>Other XLH Resources</h4>
          <ul>
            <li><a target="_blank" rel="noreferrer" href="https://www.xlhlink.com/">XLH Link</a></li>
            <li><a target="_blank" rel="noreferrer" href="https://medicine.yale.edu/pediatrics/ycxlh/">Yale Center for XLH</a></li>
            <li><a target="_blank" rel="noreferrer" href="https://www.beyondxlh.com/">Beyond XLH</a></li>
          </ul>
        </div>
      </div>
      <div>
        <img src={feature} alt="Max and Milo" />
      </div>
    </content>
  </div>
)

export default Resources
